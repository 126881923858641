import React, { useEffect, useState, useRef } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { getFlagEmoji } from './getFlagEmoji';


const CityMap = React.forwardRef(({dataTheme, homeUser, embed, isFullScreen, isEdit, homeColors}, ref)=> {
    const [isFullScreenActive, setIsFullScreenActive] = useState(false);
    const mapInstance = useRef(null); // Add this line to store map instance
    const containerRef = useRef(null);

    // console.log((window.innerWidth / 2000).toFixed(2));
    let initZoom = isEdit ? 0 : window.innerWidth > 900 ? 0.8 : window.innerWidth > 700 ? 0.5 : window.innerWidth > 600 ? 0.4 : window.innerWidth > 500 ? 0.01 : -0.5;

    if(isEdit){
        initZoom = window.innerWidth < 500 ? -0.5 : window.innerWidth < 900 ? -0.2 : 0.4;
        // console.log(initZoom);
    }
    // console.log(homeUser.cities);
    // console.log(homeColors);
    
    

    useEffect(() => {
        const map = new maplibregl.Map({
            container: 'map', // container id
            // style: 'https://demotiles.maplibre.org/style.json', // style URL
            style: `${process.env.REACT_APP_BACK_DOMAIN}/api/map?theme=${dataTheme}`,
            center: [10, 20], // starting position [lng, lat]
            // zoom: 0, // starting zoom
            zoom: initZoom, // starting zoom
            projection: {"type": "mercator"},
            attributionControl: false,
                preserveDrawingBuffer: true
        })
        // .addControl(new maplibregl.AttributionControl({
        //     compact: true
        // }), )

        // map.addControl(new maplibregl.AttributionControl({
        //     compact: true,
        // }), 'bottom-left');


        // var nav = new maplibregl.NavigationControl();
        // var nav = new maplibregl.AttributionControl({compact: true});
        // map.addControl(nav, 'top-left');


        mapInstance.current = map; // Store the map instance





        const citiesShip = {
            'type': 'FeatureCollection',
            'features': []
        };
    
        homeUser.cities.forEach(city=>{
            const feature = {
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [city.long, city.lat]
                },
                'properties': {
                    'description': `<strong>${city.city}</strong><p>${city.status}</p>`,
                    'city': city.city,
                    'status': city.status,
                    "country": city.country,
                    "continent": city.continent,
                    "long": city.long,
                    "lat": city.lat,
                },
            };
            citiesShip.features.push(feature);
        })








        map.on('load', () => {

            map.addSource('point', {
                'type': 'geojson',
                'data': citiesShip
            });
    
            citiesShip.features.forEach((feature) => {
                
                const status = feature.properties["status"];
                
                // Add a layer for this symbol type if it hasn't been added already.
                if (!map.getLayer(status) && status == 1) {
                    
                    // map.setStyle()
                    map.addLayer({
                        'id': `${status}`,
                        'source': 'point',
                        'type': 'circle',
                        'paint': {
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                0, 4, // Define the circle radius at zoom level 0
                                10, 9 // Define the circle radius at zoom level 10
                            ],
                            'circle-color': [
                                'match',
                                ['get', 'status'], // statusの値を取得
                                1, `#${homeColors.livedHex}`, // livedの場合の色
                                2, `#${homeColors.visitedHex}`, // visitedの場合の色
                                3, `#${homeColors.transitedHex}`, // Transitedの場合の色
                                '#005403' // 上記以外の場合のデフォルトの色
                            ]
                        },
                        symbolSortKey: 1000,
                        'filter': ['==', 'status', status], // Filter features based on status
                    });
                } else if (!map.getLayer(status) && status == 2) {       
                    // map.setStyle()
                    map.addLayer({
                        'id': `${status}`,
                        'source': 'point',
                        'type': 'circle',
                        'paint': {
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                0, 3, // Define the circle radius at zoom level 0
                                10, 7 // Define the circle radius at zoom level 10
                            ],
                            'circle-color': [
                                'match',
                                ['get', 'status'], // statusの値を取得
                                1, `#${homeColors.livedHex}`, // livedの場合の色
                                2, `#${homeColors.visitedHex}`, // visitedの場合の色
                                3, `#${homeColors.transitedHex}`, // Transitedの場合の色
                                '#00cf18' // 上記以外の場合のデフォルトの色
                            ]
                        },
                        'filter': ['==', 'status', status], // Filter features based on status
                    });
                } else if (!map.getLayer(status) && status == 3) {
                    // map.setStyle()
                    map.addLayer({
                        'id': `${status}`,
                        'source': 'point',
                        'type': 'circle',
                        'paint': {
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                0, 2, // Define the circle radius at zoom level 0
                                10, 6 // Define the circle radius at zoom level 10
                            ],
                            'circle-color': [
                                'match',
                                ['get', 'status'], // statusの値を取得
                                1, `#${homeColors.livedHex}`, // livedの場合の色
                                2, `#${homeColors.visitedHex}`, // visitedの場合の色
                                3, `#${homeColors.transitedHex}`, // Transitedの場合の色
                                '#bbff00' // 上記以外の場合のデフォルトの色
                            ]
                        },
                        'filter': ['==', 'status', status] // Filter features based on status
                    });
                }
                // map.moveLayer(3)
                // map.moveLayer(2)
                // map.moveLayer(1)
                // console.log(map.getStyle().layers);
            });
    
            // Create a hover effect
            const popup = new maplibregl.Popup({
                closeButton: false,
                closeOnClick: false,
                className: 'hover-popup'
            });
            
            map.on('mouseenter', '2', (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const coordinates = e.features[0].geometry.coordinates.slice();
                const city = e.features[0].properties.city;
                const country = e.features[0].properties.country;
                
                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                
                // Populate the popup and set its coordinates
                popup.setLngLat(coordinates).setHTML(`
                <div class="hover-popup-div">
                    <p class="hover-popup-city">${getFlagEmoji(country)} ${city}</p>
                </div>
                `).addTo(map);
                popup.addClassName('hover-popup-parent')
            });


        //     <div class="hover-popup-div">
        //     <p class="hover-popup-city">${city}</p>
        //     <p class="hover-popup-country">${getFlagEmoji(country)} ${country}</p>
        // </div>
                
            map.on('mouseleave', '2', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
    
            map.on('mouseenter', '1', (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const coordinates = e.features[0].geometry.coordinates.slice();
                const city = e.features[0].properties.city;
                const country = e.features[0].properties.country;
                
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                popup.setLngLat(coordinates).setHTML(`
                <div class="hover-popup-div">
                    <p class="hover-popup-city">${getFlagEmoji(country)} ${city}</p>
                </div>
                `).addTo(map);
                popup.addClassName('hover-popup-parent')
            });
                
            map.on('mouseleave', '1', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
    
            map.on('mouseenter', "3", (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const coordinates = e.features[0].geometry.coordinates.slice();
                const city = e.features[0].properties.city;
                const country = e.features[0].properties.country;
                
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                popup.setLngLat(coordinates).setHTML(`
                <div class="hover-popup-div">
                    <p class="hover-popup-city">${getFlagEmoji(country)} ${city}</p>
                </div>
                `).addTo(map);
                popup.addClassName('hover-popup-parent')
            });
                
            map.on('mouseleave', '3', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
    
            // クリックしたらWikipediaへ
            map.on('click', '2', (e) => {
                const cityName = e.features[0].properties.city;
                // const url = `/city/${cityName}/${status}`;
                const wikiUrl = `https://en.wikipedia.org/wiki/${cityName}`
                // Open the URL in a new tab or window
                window.open(wikiUrl, '_blank');
            });

            // 長押し中
            // map.on('mousedown', (e) => {
            //     map.getCanvas().style.cursor = 'grabbing';
            // });
            map.on('mouseup', (e) => {
                map.getCanvas().style.cursor = 'default';
            });
            // 以下3つはドラッグのはじめ、最中、おわり
            map.on('dragstart', (e) => {
                map.getCanvas().style.cursor = 'grabbing'; // Change the cursor to a grabbing cursor
            });
            map.on('drag', (e) => {
                map.getCanvas().style.cursor = 'grabbing'; // Keep the grabbing cursor while dragging
            });
            map.on('dragend', (e) => {
                map.getCanvas().style.cursor = 'default'; // Change the cursor to a grab cursor when drag ends
            });



        });


        return () => {
            if (mapInstance.current) {
                mapInstance.current.remove();
            }
        };


    }, [dataTheme, isFullScreenActive]);


    useEffect(() => {
        const handleFullScreenChange = () => {
            const isFullscreen = !!document.fullscreenElement;
            setIsFullScreenActive(isFullscreen);
        };

        if (containerRef && containerRef.current) {
            containerRef.current.addEventListener('fullscreenchange', handleFullScreenChange);
        }

        return () => {
            if (containerRef && containerRef.current) {
                containerRef.current.removeEventListener('fullscreenchange', handleFullScreenChange);
            }
        };
    }, [ref])







    async function downloadCityMap(map) {
        if (!map) {
            console.error('Map instance is required');
            return;
        }
        try {
            // Wait for the map to finish rendering
            await new Promise(resolve => {
                if (map.loaded()) {
                    resolve();
                } else {
                    map.once('idle', resolve);
                }
            });
    
            // Force a re-render
            map.triggerRepaint();
    
            // Wait a brief moment to ensure the render is complete
            await new Promise(resolve => setTimeout(resolve, 100));
    
            // Get the canvas and preserve WebGL drawing buffer
            const canvas = map.getCanvas();
            const gl = canvas.getContext('webgl', { preserveDrawingBuffer: true });
            
            // Create a temporary canvas to handle the image data
            const tempCanvas = document.createElement('canvas');
            const ctx = tempCanvas.getContext('2d');
            tempCanvas.width = canvas.width;
            tempCanvas.height = canvas.height;
    
            // Draw the WebGL canvas onto the temporary canvas
            ctx.drawImage(canvas, 0, 0);

              // Add logo text
            const textX = tempCanvas.width / 2; // Centered horizontally
            const textY = tempCanvas.height - 4; // Positioned near the bottom
            ctx.font = '500 20px sans-serif';
            ctx.fillStyle = dataTheme === "dark" ? 'rgb(255, 255, 255)' : "rgb(0, 0, 0)";
            ctx.textAlign = 'center';
            ctx.fillText('BokenMap', textX, textY);

            // Add logo icon
            const icon = new Image();
            icon.src = 'green-icon.png'; // Path to your icon image

            await new Promise((resolve) => {
                icon.onload = resolve;
            });

            const iconSize = 11; // ロゴアイコンのサイズ
            const iconX = textX - 61;
            const iconY = textY - 11; // テキストの右上に配置
            ctx.drawImage(icon, iconX, iconY, iconSize, iconSize);

    
            // Convert to base64 and trigger download
            const dataUrl = tempCanvas.toDataURL('image/png');
            const timestamp = new Date().toISOString().split('T')[0];
            
            // Create download link
            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = `${homeUser.homeUser.username}'s-city-map.png`;
            
            // Trigger download
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
    
            // Clean up
            tempCanvas.remove();
        } catch (error) {
            console.error('Error downloading map:', error);
            throw error;
        }
    }

    const handleDownload = async () => {
        try {
            if (!mapInstance.current) {
                throw new Error('Map is not initialized');
            }
            await downloadCityMap(mapInstance.current);
        } catch (error) {
            console.error('Failed to download map:', error);
        }
    };

    React.useImperativeHandle(ref, () => ({
        handleDownload: handleDownload,
    }));

    return (
        <div ref={containerRef} style={{width: "100%", height: "100%"}} className='city-map-container'>
            <div id='map' className={embed ? "city-map-embed" : "city-map"}></div>
        </div>
    );
})

export default CityMap;

import {React, useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Select from "react-select"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faGear, faPlane, faMap, faFlag, faMapPin } from '@fortawesome/free-solid-svg-icons';

import Profile from './Profile';
import Settings from './Settings';
import Plus from './Plus';

import "../stylesheets/Dashboard.css"

function Dashboard({user, setUser, theme, setTheme, mapColor, setMapColor}) {
    const [selectedOption, setSelectedOption] = useState('');
    // const [theme, setTheme] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();

    // function getUserPreference() {
    //   if(window.localStorage.getItem('bokenmap-darkmode-theme')) {
    //     return window.localStorage.getItem('bokenmap-darkmode-theme')
    //   }
    //   return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    // }
    // useEffect(() => {
    //     // Set theme from localStorage or system preference
    //     const currentTheme = getUserPreference();
    //     setTheme(currentTheme);

    //     // Listen for changes to the localStorage
    //     const handleStorageChange = () => {
    //         setTheme(getUserPreference());
    //     };

    //     window.addEventListener('storage', handleStorageChange);

    //     // Cleanup the event listener
    //     return () => {
    //         window.removeEventListener('storage', handleStorageChange);
    //     };
    // }, []);

    useEffect(() => {
        const path = location.pathname.split('/').pop();
        setSelectedOption(path || ''); // Set based on URL, or empty if at root
    }, [location]);


    const handleSelectChange = (option) => {
        const selectedPath = option?.value;
        setSelectedOption(selectedPath);
        if (selectedPath) {
            navigate(selectedPath); // Navigate to the selected route
        }
    };

    const options = [
        {value: "profile", label: "Profile"},
        {value: "settings", label: "Settings"},
        {value: "plus", label: "Plus"}
    ]

    const varColor = {
        "darkGray": "#303030",
        "lightGray": "#F9FAFB"
    }

    const customStyles = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: theme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: theme === "dark" ? varColor.darkGray : "#fff", // Change background color
            color: theme === "dark" ? "#fff" : "black", // Default text color
            // borderColor: theme == "dark" ? '#666' : '#ccc', // Change border color on focus
            // borderColor: state.isFocused ? (theme == "dark" ? "#fff" : "#fff") : (theme == "dark" ? "#fff" : "#fff"),
            // borderColor: state.isSelected ? (theme == "dark" ? "#fff" : "#fff") : (theme == "dark" ? "#fff" : "#fff"),
            borderRadius: "5px",
            boxShadow: 'none',
            width: "105px",
            height: "30px",
            minHeight: "0px",
            maxHeight: "30px"
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            // padding: '0 6px'
            paddingLeft: "0px",
            paddingTop: "0px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: theme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 10px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            // backgroundColor: state.isSelected 
            //     ? (theme === "dark" ? "#333" : "#666")  // Color when selected
            //     : state.isFocused 
            //         ? (theme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
            //         : (theme === "dark" ? "#000" : "#fff"),  // Normal background
            backgroundColor: state.isFocused 
                    ? (theme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (theme === "dark" ? varColor.darkGray : "#fff"),  // Normal background
            // backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#e6e6e6' : '#fff', // Change background color on select/hover
            // backgroundColor: theme == "dark" ? "black" : "white",
            color: state.isSelected
                ? (theme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (theme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : theme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: theme === "dark" ? varColor.darkGray : '#fff', // Background color of the dropdown
            width: "105px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            // color: state.isFocused ? (theme == "dark" ? '#fff' : '#ccc') : (theme == "dark" ? '#fff' : '#ccc'),
            // color: state.isSelected ? (theme == "dark" ? '#fff' : '#ccc') : (theme == "dark" ? '#fff' : '#ccc'),
            // color: theme == "dark" ? '#fff' : '#ccc', // Customize the arrow color
            // color: theme === "dark" ? '#fff' : '#666',
            // color: state.isFocused ? "hsl(255, 255 , 255, 100%)" : "hsl(255, 255 , 255, 100%)",
            color: state.isSelected
            ? (theme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (theme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : theme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
    };

    return (
        <div className='dashboard-container'>
            <Helmet>
                <title>Dashboard | BokenMap</title>
            </Helmet>

            <div className='dashboard-sidebar'>
                <div onClick={() => navigate('profile')} className='sidebar-item'><FontAwesomeIcon icon={faPen} className='sidebar-icon' />Profile</div>
                <div onClick={() => navigate('settings')} className='sidebar-item'><FontAwesomeIcon icon={faGear} className='sidebar-icon' />Settings</div>
                <div onClick={() => navigate('plus')} className='sidebar-item'><FontAwesomeIcon icon={faPlane} className='sidebar-icon' />Plus</div>
                <div  onClick={() => navigate('/edit/country')} className='sidebar-item'><FontAwesomeIcon icon={faFlag} className='sidebar-icon' />Edit Country</div>
                <div  onClick={() => navigate('/edit/city')} className='sidebar-item'><FontAwesomeIcon icon={faMapPin} className='sidebar-icon' />Edit City</div>
            </div>

            <div className='dashboard-right-container'>
                <Select options={options} className='mobile-select' value={options.find(option => option.value === selectedOption)} onChange={handleSelectChange} styles={customStyles} isSearchable={false} />
                <Routes>
                    <Route path="/" element={<Navigate to="profile" />} />
                    <Route path="profile" element={<Profile theme={theme} user={user} />} />
                    <Route path="settings" element={<Settings theme={theme} setTheme={setTheme} user={user} setUser={setUser} />} />
                    <Route path="plus" element={<Plus theme={theme} user={user} setUser={setUser} mapColor={mapColor} setMapColor={setMapColor} />} />
                </Routes>
            </div>


        </div>
    );
}

export default Dashboard;
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Select from "react-select"
import toast, {Toaster} from "react-hot-toast"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPen, faTrash, faCheck, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons';

import EditCountryMap from './EditCountryMap';
import CountryData from "../../data/country.json"
import { getColorSet } from '../../components/getColorSet';
import { getFlagEmoji } from '../../components/getFlagEmoji';

import "../../stylesheets/Edit.css"




function EditCountry({user, dataTheme, mapColor}) {
    const navigate = useNavigate()

    const userColors = getColorSet(mapColor)

    const handle = user.user.handle
    const [homeUser, setHomeUser] = useState(null)
    const [countryArr, setCountryArr] = useState([])
    const [originalCountryArr, setOriginalCountryArr] = useState([]) //originalCountryArrは、データベースから貰ったもの

    useEffect(() => {
        document.body.classList.add('edit-page');
        
        return () => {
            document.body.classList.remove('edit-page');
        };
    }, []);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${handle}`, {method: "GET", credentials: "include"})
        .then(response=>response.json())
        .then(data=>{
          if(data.exist){
            setHomeUser(data)
            setCountryArr(data.countries)
            setOriginalCountryArr(data.countries) 
          }else{
            setHomeUser(null)
          }
        }).catch(error => {console.error(error);});
    }, [])



    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCat, setSelectedCat] = useState("visited");

    const countryOptions = Object.keys(CountryData).map(key => ({
        value: key,
        label: CountryData[key].country
    }));

    const catOptions = [
        {value: "lived", label: "Lived"},
        {value: "visited", label: "Visited"},
        {value: "transited", label: "Transited"},
    ]

    const handleCountryChange = (option) => {
        const selectedCountry = option?.value;
        setSelectedCountry(selectedCountry);
    };
    const handleCatChange = (option) => {
        const selectedCat = option?.value;
        setSelectedCat(selectedCat);
    };

    const handleAdd = async () =>{
        if(selectedCountry && selectedCat){
            let statusNum
            if(selectedCat === "lived"){
                statusNum = "1"
            }else if(selectedCat === "visited"){
                statusNum = "2"
            }else if(selectedCat === "transited"){
                statusNum = "3"
            }
            const newCountry = {
                shortName: selectedCountry,
                name: CountryData[selectedCountry].country,
                continent: CountryData[selectedCountry].continent,
                status: statusNum
            }
            const existingCountryIndex = countryArr.findIndex(country => country.shortName === selectedCountry);
            if (existingCountryIndex !== -1) {
                // Update the existing country
                const updatedCountries = [...countryArr];
                updatedCountries[existingCountryIndex] = newCountry;
                setCountryArr(updatedCountries);
            } else {
                // Add new country
                setCountryArr([...countryArr, newCountry]);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/edit/country`, {
                    method: "POST",
                    credentials: "include",
                    headers: {"Content-Type": "application/json",},
                    body: JSON.stringify({
                        countryShortName: selectedCountry,
                        newStatus: statusNum
                    }),
                });
                if (!response.ok) {
                    const error = await response.json();
                    console.error("Error adding/updating country:", error);
                }
            } catch (error) {
                console.error("Error adding/updating country:", error);
            }
        }else{
            toast.error("Please select a country", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}, duration: 1000})
        }
        
    }







    const [numberCount, setNumberCount] = useState({
        "lived": 0,
        "visited": 0,   
        "transited": 0,
    })

    useEffect(() => {
        const newCount = {
            "lived": 0,
            "visited": 0,
            "transited": 0,
        };
        countryArr.forEach(country => {
            newCount.lived += country.status == 1 ? 1 : 0;
            newCount.visited += country.status == 2 ? 1 : 0;
            newCount.transited += country.status == 3 ? 1 : 0;
        });
        setNumberCount(newCount);
    }, [countryArr])
    


    const [isEditing, setIsEditing] = useState(false);

    const toggleEditMode = () => {
      setIsEditing((prev) => !prev);
    };

    const handleDelete = async (countryShortName) => {
        setCountryArr(countryArr.filter(country => country.shortName !== countryShortName))
        try {
            const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/edit/country/delete`, {
              method: "POST",
              credentials: "include",
              headers: {"Content-Type": "application/json",},
              body: JSON.stringify({ countryShortName: countryShortName }),
            });
      
            if (response.ok) {
            } else {
              const error = await response.json();
            }
          } catch (error) {
            console.error("Error deleting country:", error);
            toast.error("Failed to delete country.", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}});
          }
    }









    const varColor = {
        "darkGray": "#303030",
        "darkBack": "#181818",
        "lightGray": "#F9FAFB"
    };

    const countrySelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkBack : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "5px",
            boxShadow: 'none',
            width: "170px",
            height: "30px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28px',
            // padding: '0 6px'
            paddingLeft: "10px",
            paddingTop: "2px",
            fontSize: "14px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: dataTheme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 0px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkBack : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "13px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkBack : '#fff', // Background color of the dropdown
            width: "170px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
        input: (provided) => ({
            ...provided,
            color: (dataTheme === "dark" ? "white" : "#1E1E1E"), // Change this to your desired color
        }),
    }




    const catSelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkBack : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "5px",
            boxShadow: 'none',
            width: "100px",
            height: "30px",
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            // padding: '0 6px'
            paddingLeft: "0px",
            paddingTop: "0px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: dataTheme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 10px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkBack : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkBack : '#fff', // Background color of the dropdown
            width: "100px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
    }







    const tableCatSelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkGray : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "4px",
            boxShadow: 'none',
            width: "37px",
            height: "24px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "19px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkBack : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
            // marginTop: "2px",
            // marginBottom: "2px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkGray : '#fff', // Background color of the dropdown
            // width: "40px",
            paddingRight: "2px",
            marginTop: "4px",
            border: `1px solid ${dataTheme === "dark" ? "#424242" : varColor.lightGray}`,
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // height: '20px',
            padding: "0px",
            margin: "auto",
            // marginRight: "1px"
            marginLeft: "1px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "14px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto",
            fontSize: "4px",

        }),
    }




    const tableCatOptions = [
        { value: 'lived', color: userColors.lived },
        { value: 'visited', color: userColors.visited },
        { value: 'transited', color: userColors.transited }
    ];

    const tableCatOptionUi = (props) => {
        const { data, innerRef, innerProps } = props;
      
        return (
          <div ref={innerRef} {...innerProps} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: data.color,
                marginTop: "2px",
                marginBottom: "2px",
                marginLeft: "2px",
              }}
            ></div>
          </div>
        );
    };

    const CustomSingleValue = ({ data }) => {  
        // console.log("CustomSingleValue data:", data);
        return (
          <div>
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: data.color,
              }}
            ></div>
          </div>
        );
    };

    const handleTableCatChange = async (option, countryShortName) => {
        const updatedCountries = [...countryArr];
        const countryIndex = updatedCountries.findIndex(country => country.shortName === countryShortName);
        if (countryIndex !== -1) {
            updatedCountries[countryIndex].status = option.value === 'lived' ? "1" : option.value === 'visited' ? "2" : "3";
            setCountryArr(updatedCountries);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/edit/country`, {
                    method: "POST",
                    credentials: "include",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        countryShortName: countryShortName,
                        newStatus: updatedCountries[countryIndex].status
                    }),
                });

                if (!response.ok) {
                    const error = await response.json();
                    console.error("Error updating country:", error);
                    toast.error(error.message, {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}});
                }
            } catch (error) {
                console.error("Error updating country:", error);
                toast.error("Failed to update country.", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}});
            }
        }
    };



    const [searchQuery, setSearchQuery] = useState("")

    const filteredCountries = countryArr.filter(country => country.name.toLowerCase().includes(searchQuery.toLowerCase()))






    const sortMenuRef = useRef(null);
    const sortIconRef = useRef(null);
    const [sortMenu, setSortMenu] = useState(false);

    const handleSortChange = (option) => {
        const sortedCountries = [...countryArr].sort((a, b) => {
            if (option === 'country-a') {
                return b.name.localeCompare(a.name);
            } else if (option === 'country-z') {
                return a.name.localeCompare(b.name);
            } else if (option === 'continent-a') {
                return b.continent.localeCompare(a.continent);
            } else if (option === 'continent-z') {
                return a.continent.localeCompare(b.continent);
            }
            return 0;
        });
        setCountryArr(sortedCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortMenuRef.current && !sortMenuRef.current.contains(event.target) && sortIconRef.current && !sortIconRef.current.contains(event.target)) {
                setSortMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className='edit-page'>
            <Helmet>
                <title>Edit Country | BokenMap</title>
            </Helmet>

            <aside className='edit-sidebar'>
                <div className="nav-left" style={{marginTop: "25px", marginLeft: "22px"}}>
                    <div className="nav-left-icon" onClick={() => navigate(`/`)}></div>
                    <h1 className="nav-left-name" onClick={() => navigate(`/`)}>BokenMap</h1>
                </div>
                <div className='edit-sidebar-handle' onClick={() => navigate(`/${handle}`)}><FontAwesomeIcon icon={faArrowLeft} className='sidebar-back-arrow' /><div className='sidebar-handle-text'>{handle}</div></div>
                <div className="edit-sidebar-row">
                    <input type="text" className='search-input' placeholder='Search your list' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    <div className='sidebar-sort'>
                        <div className='sort-icon-container'>
                            <FontAwesomeIcon icon={faArrowDownWideShort} className='sort-icon' onClick={()=>{setSortMenu(!sortMenu)}} ref={sortIconRef} />
                        </div>
                        {sortMenu && (
                            <div className='sort-menu' ref={sortMenuRef}>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("country-a")}}>Country A-Z</div>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("country-z")}}>Country Z-A</div>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("continent-a")}}>Continent A-Z</div>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("continent-z")}}>Continent Z-A</div>
                            </div>
                        )}
                    </div>
                    {isEditing ? (
                        <div className='check-container' onClick={toggleEditMode} data-tooltip-content="Edit Countries" data-tooltip-id="edit-sidebar-button">
                            <FontAwesomeIcon icon={faCheck} className='edit-sidebar-icon check-icon' />
                        </div>
                    ) : (
                        <div className='edit-sidebar-button' onClick={toggleEditMode} data-tooltip-content="Edit Countries" data-tooltip-id="edit-sidebar-button">
                            <FontAwesomeIcon icon={faPen} className='edit-sidebar-icon' />
                        </div>
                    )}
                </div>
                <div className='country-table'>
                    {homeUser ? filteredCountries.slice().reverse().map((country, index) => {
                        return (
                            <div key={index} className='country-row'>
                                <div className="country-cell-left">
                                    <div className='country-cell-cat'>
                                        {isEditing ?
                                            <Select 
                                                options={tableCatOptions} 
                                                components={{ Option: tableCatOptionUi, SingleValue: CustomSingleValue }} 
                                                value={tableCatOptions.find(option => option.value === (country.status == 1 ? "lived" : country.status == 2 ? "visited" : "transited"))}
                                                onChange={(option) => handleTableCatChange(option, country.shortName)} 
                                                styles={tableCatSelectStyle} 
                                                isSearchable={false} 
                                            /> :
                                            <div className={`cat-icon-${country.status == "1" ? "lived" : country.status == "2" ? "visited" : "transited"}`} style={{backgroundColor: userColors[country.status == "1" ? "lived" : country.status == "2" ? "visited" : "transited"]}}></div>
                                        }
                                    </div>
                                    <div className='country-cell-name'>{getFlagEmoji(country.shortName)} {country.name}</div>
                                </div>
                                <div className='country-cell-func'>
                                    <div className='country-each-delete'>
                                        {isEditing && (<FontAwesomeIcon icon={faTrash} className='country-each-delete-icon' onClick={()=>{handleDelete(country.shortName)}} />)}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <></>}
                    {filteredCountries.length === 0 && (
                        <div className='no-country-text-container'>
                            <div className='no-country-text'>No countries</div>
                        </div>
                    )}
                </div>
            </aside>
            <div className='edit-content'>
                <div className='add-row-container'>
                    {/* <input type="text" /> */}
                    <h3>Select a new country, and add them to your list!</h3>
                    <p className='edit-info-text'>If a country is already in your list, it will be updated.</p>
                    <div className="add-row">
                        <div style={{display: "flex"}}>
                            <Select options={countryOptions} className='country-select' value={countryOptions.find(option => option.value === selectedCountry)} onChange={handleCountryChange} styles={countrySelectStyle} isSearchable={true} placeholder="Select Country" />
                            <Select options={catOptions} className='cat-select' value={catOptions.find(option => option.value === selectedCat)} onChange={handleCatChange} styles={catSelectStyle} isSearchable={false} />
                        </div>
                        <div className='country-add-button' onClick={handleAdd}>Add</div>
                    </div>
                </div>
                <div className='map-container' style={{ aspectRatio: "auto", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <EditCountryMap dataTheme={dataTheme} countryArr={countryArr} userColors={userColors} />
                </div>
                <div className='edit-data-container'>
                    <div className="edit-data-each">
                        <div className="edit-data-top">{numberCount.lived}</div>
                        <div className="edit-data-bottom">
                            <div className="edit-data-icon-lived" style={{backgroundColor: userColors.lived}}></div>
                            <div className="edit-data-text">Lived</div>
                        </div>
                    </div>
                    <div className="edit-data-each">
                        <div className="edit-data-top">{numberCount.visited}</div>
                        <div className="edit-data-bottom">
                            <div className="edit-data-icon-visited" style={{backgroundColor: userColors.visited}}></div>
                            <div className="edit-data-text">Visited</div>
                        </div>
                    </div>
                    <div className="edit-data-each">
                        <div className="edit-data-top">{numberCount.transited}</div>
                        <div className="edit-data-bottom">
                            <div className="edit-data-icon-transited" style={{backgroundColor: userColors.transited}}></div>
                            <div className="edit-data-text">Transited</div>
                        </div>
                    </div>
                </div>
                <div className='edit-header'>
                    {/* <h2>Edit Country<span onClick={()=>{navigate(`/${handle}`)}}>@{handle}</span></h2> */}
                    <h2>Edit Country</h2>
                    <div className='edit-other' onClick={()=>{navigate(`/edit/city`)}}>Edit cities instead</div>
                </div>
            </div>

        </div>
    );
}

export default EditCountry;